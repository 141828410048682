import { combineConfig } from 'src/utilities/config';
import { VersionSpecificConfig, URLSet, StringSet } from '../config';
import { env } from '../configs/config.all';

const segmentSamsungURLs: URLSet = {
    PROD: 'https://cdn.segment.com/analytics.js/v1/iUX7m87nAWXB2IP2YgxREueoxsVDRu1N/analytics.min.js',
    PP: 'https://cdn.segment.com/analytics.js/v1/D0pqoNd9NevoDYSSMecSZuBNvVJKmfMG/analytics.min.js',
    LOCAL: 'https://cdn.segment.com/analytics.js/v1/D0pqoNd9NevoDYSSMecSZuBNvVJKmfMG/analytics.min.js',
};

const drModeSamsungURLs: URLSet = {
    PROD: 'https://dr.optussport.tv/assets-samsungtv.json',
    PP: 'https://dr.optussport.info/assets-samsungtv.json',
    LOCAL: 'https://dr.optussport.info/assets-samsungtv.json',
};

const brazeSamsungKeys: StringSet = {
    PROD: 'ff283972-db22-4897-9e38-3d7d8913ba44',
    PP: 'a5482a5f-d410-4e68-a101-623cf024a24f',
    LOCAL: 'a5482a5f-d410-4e68-a101-623cf024a24f',
};

const config_samsung_version_specific: VersionSpecificConfig = {
    // x.y.0 is concidered a later version of x.y
    '0': {
        platform: 'SamsungTV',
        noAnimations: true,
        segmentLibUrl: segmentSamsungURLs[env],
        drModeCheckURL: drModeSamsungURLs[env],
        playbackSettingsSpeedActive: false,
        playbackSupportsCmafLive: false,
        playbackSupportsCmafVod: false,
        YoSpaceActiveOnLive: true,
        YoSpaceActiveOnVod: true,
        adUIEnabledOnLive: false,
        adUIEnabledOnVod: true,
        BrazeApiKey: brazeSamsungKeys[env],

        bitmovinPlayerConfig: {
            playback: {
                autoplay: true,
                videoCodecPriority: ['avc', 'hevc', 'av1'],
            },
        },
    },
    '5.0': {
        noAnimations: false,
        playbackSettingsSpeedActive: true,
    },
    '5.5': {
        exitAppPopupEnabled: true,
    },
    '6.1': {
        playbackSupportsCmafLive: true,
        playbackSupportsCmafVod: true,
        bitmovinPlayerConfig: {
            playback: {
                autoplay: true,
                videoCodecPriority: ['hevc', 'avc', 'av1'],
            },
        },
    },
};

const config_samsung = (version: string) => combineConfig(config_samsung_version_specific, version);
export default config_samsung;
